export const LIST_OF_LABEL = () => {
    return [
        {type: 'red', libelle: 'Rouge'},
        {type: 'warning', libelle: 'Orange'},
        {type: 'primary', libelle: 'Bleu'},
        {type: 'success', libelle: 'Vert'},
    ].sort((a,b) => a.libelle.localeCompare(b.libelle))
}

export const EVENT_DURATION = 30
export const EVENT_ALL_DAY_DURATION = 1439
export const EVENT_UPDATE_TOLERANCE = 36000